@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* CSS Reset */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Root Variables */
:root {
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  --font-weight-default: 600;
  --divider-color: rgba(255,255,255,1);
}

/* Base Styles */
html {
  height: 100%;
  font-size: 14px; /* Base font size for rem units */
}

body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: var(--font-primary);
  font-weight: var(--font-weight-default);
  font-size: 1rem;
  font-style: normal;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

/* Typography */
body,
h1, h2, h3, h4, h5, h6,
p, div, span,
button, input, a {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-default);
}

/* Code blocks */
code {
  font-family: var(--font-primary);
}

/* Media Queries */
@media screen and (max-width: 768px) {
  html {
    font-size: 12px; /* Slightly smaller base font size for mobile */
  }
}

/* Print Styles */
@media print {
  body {
    overflow: visible;
  }

  #root {
    overflow: visible;
  }
}