@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;700&display=swap');

body {
  font-family: 'Figtree', sans-serif;
  background-color: #15263C; /* Light grey background color */
  margin: 0;
  padding: 0;
}

.userGuideContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%; 
  width: 100%; 
  margin: 0 auto;
  color: #15263C; /* Dark text color */
}

.section {
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: #E9E8E4; /* Light grey background for sections */
  width: 100%;
  border-radius: 4px;
  border: 0.1rem solid #15263C; /* Light blue border for sections */
}

.sectionTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #2278B2; /* Blue color for section titles */
}

.heading {
  font-size: 1rem; /* Adjust the font size as needed */
  font-weight: bold;
  margin-top: 5px; /* Reduce the space above the heading */
  margin-bottom: 10px;
}


.cardTitle {
  font-size: 3rem; /* Increase the font size for the card title */
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #15263C; /* Blue color for card titles */
}

.title {
  font-size: 1.5rem; /* Increase the font size for the title */
  margin-bottom: 5px; /* Reduce the space below the title */
}



.contentWrapper {
  display: flex;
  flex-direction: column;
}

.sectionImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.textContent {
  margin-bottom: 20px;
}

.catchPhrase, .catchMotto, .heading, .description, .subdescription, .cardSubtitle {
  margin-bottom: 10px;
}

.detailsList {
  list-style-type: none; /* Remove bullet points */
  padding-left: 0; /* Remove left padding */
}

.detailItem {
  margin-bottom: 5px;
}

.videoWrapper {
  margin-bottom: 20px;
  text-align: center; /* Center the video */
}

.videoContainer {
  position: relative;
  padding-bottom: calc(56.25% - 1.5in); /* 16:9 aspect ratio reduced by 1.5 inches vertically */
  height: 0;
  overflow: hidden;
  width: calc(100% - 2in); /* Reduce the width by 2 inches */
  background: #000;
  margin: 0 auto; /* Center the video container */
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.presentationWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 0 auto 16px auto; /* Center the presentation and add bottom margin */
}

.presentationWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.miroBoardContainer {
  margin-bottom: 20px;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}

.miroBoardContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.actions {
  text-align: center;
}

.close-button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #FAAA3E; /* Orange button color */
  border: none;
  border-radius: 4px;
  color: #15263C; /* Dark text color */
}

.close-button:hover {
  background-color: #C5EFFF; /* Light blue background color on hover */
}

.searchContainer {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  background-color: #2278B2; /* Match the background color of the sidebar */
  padding: 10px;
  z-index: 1000; /* Ensure the search bar is on top of other elements */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInput {
  width: calc(100% - 20px - 1.5cm); /* Reduce the width by 1.5 cm from the right side */
  margin-right: 1.5cm; /* Add margin to the right */
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 2px;
  text-align: center; /* Center the search text */
}

.searchControls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px; /* Adjust this value to position the buttons from the left edge */
}

.searchButton {
  padding: 5px 10px;
  margin: 0 5px; /* Adjust the margin to bring the buttons closer together */
  font-size: 1em;
  cursor: pointer;
  background-color: #FAAA3E; /* Orange button color */
  border: none;
  border-radius: 4px;
  color: #15263C; /* Dark text color */
}

.searchButton:hover {
  background-color: #C5EFFF; /* Light blue background color on hover */
}

.pageCounter {
  margin-left: 0.25in; /* Adjust margin to position the page counter */
  font-size: 1em;
  color: #E9E8E4; /* Light text color */
  width: calc(2cm); /* Make the page counter box 2 cm wide */
  text-align: center; /* Center the text inside the page counter */
}

.videoWrapper {
  text-align: center; /* Center the video */
}

.videoWrapper iframe {
  width: 100%; /* Double the width */
  height: 100%; /* Double the height */
}

