.open {
    height: 100vh;
    width: calc(100vw - 300px);
  }
  
  .closed {
    height: 100vh;
    width: calc(100vw - 64px);
  }
  
  .login {
    height: 100vh;
    width: 100vw;
  }

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
}
  
.appBarContainer {
  position: fixed;
  height: 64px;
  width: "100%";
}
  
.sideNavContainer{
    width: "100%"; 
    height: "100%";
}