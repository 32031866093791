/* frontend/src/features/layout/sideBar/SideNavbar.module.css */
/* SideNavbar.module.css */
.drawer {
  width: var(--drawer-width);
  flex-shrink: 0;
  position: relative;
  z-index: 1; 
}

/* Target the MUI Drawer paper class */
.drawer :global(.MuiDrawer-paper) {
  position: fixed;
  top: 64px;  
  height: calc(100% - 64px);  
  width: var(--drawer-width);
  background-color: var(--drawer-bg-color);
  color: var(--drawer-text-color);
  box-sizing: border-box;
  border-right: 0.1em solid var(--drawer-border-color);
  z-index: 10;  
  transition: width var(--transition-duration) var(--transition-easing);
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.listItemButton {
  padding-top: 16px;
  padding-bottom: 16px;
  color: white;
}

.listItemIcon {
  color: white;
  margin-left: 8px;
}

.divider {
  height: 1px;
  background-color: white;
}

.logoutDivider {
  height: 2px;
  background-color: white;
}

.typography {
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.hidden {
  opacity: 0;
  transition: opacity 50ms ease-out 0ms;
}

.visible {
  opacity: 1;
  transition: opacity 50ms ease-in 0ms;
}