@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;700&display=swap');

body {
  font-family: 'Figtree', sans-serif;
}

.sideNav { 
  position: fixed;
  top: 64px; /* AppBar height */
  left: 0;
  width: 300px;
  height: calc(100vh - 64px); /* Subtract AppBar height from viewport height */
  background-color: #ffffff;
  padding: 0 20px 80px 20px; /* Add bottom padding to ensure scrollable content is accessible */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  color: #E9E8E4;
  z-index: 999; /* Keep above content but below AppBar */
  transition: transform 0.1s step-start;
}

/* Adjust width for smaller screens */
@media (max-width: 768px) {
  .sideNav {
    width: 220px;
  }
}

/* Handle open/closed states */
.sideNav.open {
  transform: translateX(0);
}

.sideNav.closed {
  transform: translateX(-100%);
}

.glossaryHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 5px;
  z-index: 2;
  margin-bottom: 10px;
}

.sideNav h4 {
  font-size: 1.5rem; /* Slightly reduced for better fit */
  margin-top: 0;
  margin-bottom: 5px;
  color: #FAAA3E;
  text-align: center;
  width: 100%;
}

.glossaryIcon {
  width: 100%; /* Make responsive to the container */
  max-width: 180px; /* Set a maximum size */
  height: auto;
  margin-bottom: 5px;
}

.sideNav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 80px; /* Add space at the bottom for scrolling */
}

.sideNav ul li {
  margin: 8px 0; /* Slightly increased for better readability */
  padding: 2px 0;
  display: block; /* Ensure each item takes full width */
}

.sideNav ul li a {
  text-decoration: none;
  color: #15263C;
  display: block; /* Make the entire link area clickable */
  padding: 3px 0;
  word-wrap: break-word; /* Ensure long text wraps */
}

.sideNav ul li a:hover {
  text-decoration: underline;
  color: #2278B2; /* Changed to a blue that's visible on white background */
}

.cardTitleLink {
  font-size: 1.3em; /* Slightly reduced for better fit */
  font-weight: bold;
  color: #2278B2;
}

.cardTitleSpacer {
  height: 16px; /* Slightly reduced spacing */
}

.titleLink {
  font-size: 1em;
  color: #816b10;
  padding-left: 8px; /* Indent regular links */
}

/* Add scrollbar styling for better visibility */
.sideNav::-webkit-scrollbar {
  width: 8px;
}

.sideNav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sideNav::-webkit-scrollbar-thumb {
  background: #FAAA3E;
  border-radius: 4px;
}

.sideNav::-webkit-scrollbar-thumb:hover {
  background: #2278B2;
}